.drop-over-downward td {
  border-bottom: 2px solid !important;
}

.drop-over-upward td {
  border-top: 2px solid;
}

.drag-icon {
  color: #000;
}

/* .draggable-row:hover svg {
  color: 'red' !important;
  background-color: 'yellow';
} */
